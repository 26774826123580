import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import store from "./store";
import axios from 'axios';
import ElementUI from 'element-ui';
import JsonCSV from 'vue-json-csv';
import moment from 'moment';

// router setup
import router from './routes/router';
// plugin setup
Vue.use(DashboardPlugin);

// import ElementUI's styles
import 'element-ui/lib/theme-chalk/index.css';

// use ElementUI's plugin
Vue.use(ElementUI);
Vue.component('downloadCsv', JsonCSV);
//Api config
Vue.prototype.$http = axios;
//prod
// Vue.prototype.$baseUrl = 'https://api.kaziapps.com/?f=';
// Vue.prototype.$cdnUrl = 'https://cdn.kaziapps.com/';
//dev
Vue.prototype.$baseUrl = 'https://apitest.kaziapps.com/?f=';
Vue.prototype.$cdnUrl = 'https://cdntest.kaziapps.com/';

Vue.prototype.$publicToken = 'pubL1Ct0K3n';

//moment js
Vue.prototype.$moment = moment;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
